// Generated by Framer (20caf11)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["JW8Q4Qeq4", "zwUzm9YMk"];

const serializationHash = "framer-u1a7z"

const variantClassNames = {JW8Q4Qeq4: "framer-v-1ubuqcj", zwUzm9YMk: "framer-v-1wesmyb"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Close: "zwUzm9YMk", Hamburger: "JW8Q4Qeq4"}

const getProps = ({click, height, id, width, ...props}) => { return {...props, v9sT80Xq_: click ?? props.v9sT80Xq_, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "JW8Q4Qeq4"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, v9sT80Xq_, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "JW8Q4Qeq4", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1g291l4 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (v9sT80Xq_) {const res = await v9sT80Xq_(...args);
if (res === false) return false;}
setVariant("zwUzm9YMk")
})

const onTap11tk065 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (v9sT80Xq_) {const res = await v9sT80Xq_(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (baseVariant === "zwUzm9YMk") return false
return true
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1ubuqcj", className, classNames)} data-border data-framer-name={"Hamburger"} data-highlight layoutDependency={layoutDependency} layoutId={"JW8Q4Qeq4"} onTap={onTap1g291l4} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "rgb(238, 238, 238)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 20, borderBottomRightRadius: 20, borderTopLeftRadius: 20, borderTopRightRadius: 20, boxShadow: "0px 3px 10px 0px rgba(0, 0, 0, 0.03)", ...style}} {...addPropertyOverrides({zwUzm9YMk: {"data-framer-name": "Close", onTap: onTap11tk065}}, baseVariant, gestureVariant)}><motion.div className={"framer-7du42e"} layoutDependency={layoutDependency} layoutId={"YTQyKE5Vr"} style={{backgroundColor: "rgb(0, 0, 0)", borderBottomLeftRadius: 2, borderBottomRightRadius: 2, borderTopLeftRadius: 2, borderTopRightRadius: 2, rotate: 0}} variants={{zwUzm9YMk: {rotate: 45}}}/>{isDisplayed() && (<motion.div className={"framer-1qrlb3q"} layoutDependency={layoutDependency} layoutId={"AuSUc7u7w"} style={{backgroundColor: "rgb(0, 0, 0)", borderBottomLeftRadius: 2, borderBottomRightRadius: 2, borderTopLeftRadius: 2, borderTopRightRadius: 2}}/>)}<motion.div className={"framer-k5lyia"} layoutDependency={layoutDependency} layoutId={"EHGJunWwK"} style={{backgroundColor: "rgb(0, 0, 0)", borderBottomLeftRadius: 2, borderBottomRightRadius: 2, borderTopLeftRadius: 2, borderTopRightRadius: 2, rotate: 0}} variants={{zwUzm9YMk: {rotate: -45}}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-u1a7z.framer-1ya2n5l, .framer-u1a7z .framer-1ya2n5l { display: block; }", ".framer-u1a7z.framer-1ubuqcj { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 4px; height: 35px; justify-content: center; overflow: hidden; padding: 15px; position: relative; width: 35px; will-change: var(--framer-will-change-override, transform); }", ".framer-u1a7z .framer-7du42e, .framer-u1a7z .framer-1qrlb3q, .framer-u1a7z .framer-k5lyia { flex: none; height: 1px; overflow: visible; position: relative; width: 16px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-u1a7z.framer-1ubuqcj { gap: 0px; } .framer-u1a7z.framer-1ubuqcj > * { margin: 0px; margin-bottom: calc(4px / 2); margin-top: calc(4px / 2); } .framer-u1a7z.framer-1ubuqcj > :first-child { margin-top: 0px; } .framer-u1a7z.framer-1ubuqcj > :last-child { margin-bottom: 0px; } }", ".framer-u1a7z.framer-v-1wesmyb .framer-7du42e, .framer-u1a7z.framer-v-1wesmyb .framer-k5lyia { left: calc(51.428571428571445% - 16px / 2); position: absolute; top: calc(51.428571428571445% - 1px / 2); z-index: 1; }", ".framer-u1a7z[data-border=\"true\"]::after, .framer-u1a7z [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 35
 * @framerIntrinsicWidth 35
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"zwUzm9YMk":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"v9sT80Xq_":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerWzeMkKFRw: React.ComponentType<Props> = withCSS(Component, css, "framer-u1a7z") as typeof Component;
export default FramerWzeMkKFRw;

FramerWzeMkKFRw.displayName = "Hamburger Icon";

FramerWzeMkKFRw.defaultProps = {height: 35, width: 35};

addPropertyControls(FramerWzeMkKFRw, {variant: {options: ["JW8Q4Qeq4", "zwUzm9YMk"], optionTitles: ["Hamburger", "Close"], title: "Variant", type: ControlType.Enum}, v9sT80Xq_: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerWzeMkKFRw, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})